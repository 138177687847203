<template>
  <div class="main">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "LayoutMain",
};
</script>

<style scoped>
</style>